.root {
  overflow: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hiddenScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hiddenScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.z-max {
  z-index: 9999;
}

//
.nc-will-change-transform {
  will-change: transform;
}
.nc-will-change-top {
  will-change: top;
}
//

.nc-SectionClientSay .glide__bullet--active {
  @apply bg-neutral-700;
}
.nc-GallerySlider .glide__bullet--active {
  @apply bg-white w-2 h-2;
}

//
.nc-card-title {
  @apply transition-colors  hover:text-primary-900 dark:hover:text-primary-300 duration-75;
}

//
.nc-PostCardCommentBtn,
.nc-PostCardLikeAction,
.nc-CommentCardLikeReply > button {
  &:hover {
    span {
      color: inherit;
    }
  }
}

//
.nc-box-has-hover {
  @apply bg-white rounded-3xl border border-neutral-200 border-opacity-70 hover:bg-neutral-50 hover:shadow-xl hover:border-transparent transition-shadow;
}

.nc-dark-box-bg-has-hover {
  @apply dark:bg-neutral-800 dark:bg-opacity-30  dark:border dark:border-neutral-700 dark:hover:bg-neutral-800 dark:hover:shadow-2xl;
}

.nc-dark-box-bg {
  @apply dark:bg-neutral-800 dark:bg-opacity-30  dark:border dark:border-neutral-700 dark:shadow-2xl;
}

//
.nc-origin-100 {
  transform-origin: 100% 50% 0px;
}
.nc-origin-50 {
  transform-origin: 50% 50% 0px;
}

// HERO
.nc-hero-field-padding {
  @apply py-3 px-4 md:py-4 md:px-7 xl:py-6 xl:px-10;
}

.nc-flex-1 {
  flex: 1 0 0%;
}

.nc-flex-1\.5 {
  flex: 1.5 0 0%;
}
.nc-flex-1\.5-auto {
  flex: 1.5 0 auto;
}

.nc-flex-2 {
  flex: 2 0 0%;
}

.nc-flex-2-auto {
  flex: 2 0 auto;
}

.nc-flex-2\.5 {
  flex: 2.5 0 0%;
}

@screen lg {
  .lg\:nc-flex-1\.5 {
    flex: 1.5 0 0%;
  }
  .lg\:nc-flex-1\.5-auto {
    flex: 1.5 0 auto;
  }

  .lg\:nc-flex-2 {
    flex: 2 0 0%;
  }

  .lg\:nc-flex-2-auto {
    flex: 2 0 auto;
  }

  .lg\:nc-flex-2\.5 {
    flex: 2.5 0 0%;
  }
}

.nc-icon-field {
  @apply w-5 h-5 lg:w-7 lg:h-7;
}
.nc-icon-field-2 {
  @apply w-5 h-5 lg:w-8 lg:h-8;
}
//
button.gm-control-active.gm-fullscreen-control {
  @apply rounded-xl #{!important};
}

//
.nc-FiveStartIconForRate {
  svg:hover ~ svg {
    @apply text-neutral-300;
  }
}

// LISTING PAGE DETAIL
.listingSection__wrap {
  @apply w-full flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 pb-10 px-0 sm:p-4 xl:p-8;
}

//
input[type='time']::-webkit-calendar-picker-indicator {
  @apply dark:bg-neutral-500 rounded-md;
}

[type='button'] {
  -webkit-appearance: none !important;
}

//
.rc-slider-track {
  @apply bg-primary-400;
}
.rc-slider-handle {
  @apply border-primary-300;
}

//
.nc-PropertyCardH {
  .nc-BtnLikeIcon--liked {
    @apply text-red-500 dark:text-red-500;
  }
}

//
.ncSectionLogos {
  img {
    @apply w-full;
    max-width: 200px;
  }
}

//
.modalPhotos-single-gallery {
  .glide__bullet--active {
    @apply block;
  }
}

.menuIsNew_lv1 {
  > a::before {
    content: 'New!';
    position: absolute;
    top: -2px;
    right: 0;
    display: inline-block;
    padding: 0 5px;
    line-height: 15px;
    border-radius: 4px;
    background-color: #f35656;
    color: #fff;
    font-size: 10px;
    font-weight: normal;
  }
}
.menuIsNew {
  > a::after {
    content: 'New!';
    display: inline-block;
    padding: 0 5px;
    line-height: 15px;
    border-radius: 4px;
    background-color: #f35656;
    color: #fff;
    font-size: 10px;
    margin-left: 6px;
  }
}

.ncBus {
  border: 5px solid #d8d8d8;
  padding: 3px;
}

.nc-BusSeat {
  display: flex;
  padding: 2px;
  color: #000;
  position: relative;
  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
  }
  input[type='checkbox']:checked {
    + label {
      color: #fff;
      background: #0260d7;
      -webkit-animation-name: rubberBand;
      animation-name: rubberBand;
      animation-duration: 300ms;
      animation-fill-mode: both;
    }
  }
  input[type='checkbox']:disabled {
    + label {
      background: #ec0c0c;
      text-indent: -9999px;
      overflow: hidden;
      &:after {
        content: 'X';
        text-indent: 0;
        position: absolute;
        top: 4px;
        left: 50%;
        transform: translate(-50%, 0%);
      }
      &:hover {
        box-shadow: none;
        cursor: not-allowed;
      }
    }
  }
  label {
    display: block;
    position: relative;
    width: 36px;
    margin: 0 auto;
    text-align: center;
    font-weight: bold;
    padding: 4px 0;
    background: #b6b7b7;
    border-radius: 5px;
    animation-duration: 300ms;
    animation-fill-mode: both;

    &:before {
      content: '';
      position: absolute;
      width: 36px;
      height: 36px;
      top: 1px;
      left: 50%;
      transform: translate(-50%, 0%);
      background: rgba(255, 255, 255, 0.4);
      border-radius: 3px;
    }
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 0px 2px #5c6aff;
    }
  }
}

@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

// MoveUpDown

.moveUpDown {
  -webkit-animation: MoveUpDown 2s linear infinite;
  animation: MoveUpDown 2s linear infinite;
}

@-webkit-keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
